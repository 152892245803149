import axios from "axios";

import { useAuthStore } from "@/stores/AuthStore";

import apiService from "./api.service";
class AuthService {
  getCognitoCredentials() {
    const authStore = JSON.parse(localStorage.getItem("authStore"));
    if (authStore && authStore.cognitoCredentials) {
      return authStore.cognitoCredentials;
    }
    return null;
  }

  authHeaderCognito() {
    const cognitoCredentials = this.getCognitoCredentials();

    if (cognitoCredentials) {
      return {
        "x-idtoken": cognitoCredentials.idToken,
        "x-accesstoken": cognitoCredentials.accessToken,
      };
    }
    return {};
  }

  async cognitoAuth({ username, password }) {
    const path = "https://cognito-idp.eu-west-1.amazonaws.com";
    const formData = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/x-amz-json-1.1",
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      },
    };

    try {
      const response = await axios.post(path, formData, config);
      const { AuthenticationResult } = response.data;
      return AuthenticationResult;
    } catch (error) {
      console.error("Error during authentication:", error);
      throw error;
    }
  }

  async cognitoRefreshToken({ refreshToken }) {
    const path = "https://cognito-idp.eu-west-1.amazonaws.com";
    const formData = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/x-amz-json-1.1",
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      },
    };

    try {
      const response = await axios.post(path, formData, config);
      const { AuthenticationResult } = response.data;
      return AuthenticationResult;
    } catch (error) {
      console.error("Error during token refresh:", error);
      await useAuthStore().logout();
      throw error;
    }
  }

  async logoutCognito() {
    try {
      await apiService.post("/session/logout", {});
    } catch (error) {
      console.error("Error during cognito logout:", error);
    }
  }
}

export default new AuthService();
