import { defineStore } from "pinia";
import { ref } from "vue";

import { defaultAnimalsFilter } from "@/constants/defaults";
import { AnimalsFilter, AnimalTable } from "@/types/types";

import { useContractOperatorStore } from "./ContractOperatorStore";

export const useAnimalOperatorStore = defineStore(
  "animalOperatorStore",
  () => {
    // State as refs
    const animals = ref<AnimalTable[]>([]);
    const selectedAnimal = ref<AnimalTable>({} as AnimalTable);
    const showAnimalDetails = ref(false);
    const showSlaughterForm = ref(false);
    const toggleLoadingAnimals = ref(false);
    const showAnimalsFilter = ref(false);
    const sortColumn = ref("id");
    const sortOrder = ref("asc");
    const sortOrderOptions = ref([
      { label: "Ascendente", value: "asc" },
      { label: "Descendente", value: "desc" },
    ]);
    const sortOptions = ref([
      { label: "Recientes", value: "id" },
      { label: "Crotal", value: "crotal" },
      { label: "Estado", value: "state.name" },
      { label: "Raza", value: "breed.name" },
      { label: "Sexo", value: "sex.name" },
      { label: "Número de Contrato", value: "contract_number" },
      { label: "Fecha de Nacimiento", value: "birthDate" },
      { label: "Explotación", value: "ranchId.cea" },
    ]);
    const animalsFilter = ref({ ...defaultAnimalsFilter });

    // Actions as functions
    const openAnimalDetails = (animal: AnimalTable) => {
      const contractOperatorStore = useContractOperatorStore();
      contractOperatorStore.hideContractDetails();
      selectedAnimal.value = animal;
      showAnimalDetails.value = true;
    };

    const hideAnimalDetails = () => {
      showAnimalDetails.value = false;
    };

    const toggleAnimalDetails = () => {
      const contractOperatorStore = useContractOperatorStore();
      contractOperatorStore.hideContractDetails();
      showAnimalDetails.value = !showAnimalDetails.value;
    };

    const openSlaughterForm = (animal: AnimalTable) => {
      selectedAnimal.value = animal;
      showSlaughterForm.value = true;
    };

    const closeSlaughterForm = () => {
      showSlaughterForm.value = false;
    };

    const toggleLoadAnimals = () => {
      toggleLoadingAnimals.value = !toggleLoadingAnimals.value;
    };

    const setAnimals = (newAnimals: AnimalTable[]) => {
      animals.value = newAnimals;
      if (selectedAnimal.value) {
        const refreshedAnimal = animals.value.find(
          (animal) => animal.id === selectedAnimal.value.id,
        );
        if (refreshedAnimal) {
          selectedAnimal.value = refreshedAnimal;
        }
      }
    };

    const openAnimalsFilter = () => {
      showAnimalsFilter.value = true;
    };

    const hideAnimalsFilter = () => {
      showAnimalsFilter.value = false;
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
    };

    const cleanAnimalFilters = () => {
      animalsFilter.value = defaultAnimalsFilter;
    };

    const setAnimalsFilter = (filters: AnimalsFilter) => {
      animalsFilter.value = filters;
    };

    const $reset = () => {
      animals.value = [];
      selectedAnimal.value = {} as AnimalTable;
      showAnimalDetails.value = false;
      showSlaughterForm.value = false;
      toggleLoadingAnimals.value = false;
      showAnimalsFilter.value = false;
      sortColumn.value = "id";
      sortOrder.value = "asc";
      animalsFilter.value = { ...defaultAnimalsFilter };
    };

    return {
      // State
      animals,
      selectedAnimal,
      showAnimalDetails,
      showSlaughterForm,
      toggleLoadingAnimals,
      showAnimalsFilter,
      sortColumn,
      sortOrder,
      sortOrderOptions,
      sortOptions,
      animalsFilter,
      // Actions
      openAnimalDetails,
      hideAnimalDetails,
      toggleAnimalDetails,
      openSlaughterForm,
      closeSlaughterForm,
      toggleLoadAnimals,
      setAnimals,
      openAnimalsFilter,
      hideAnimalsFilter,
      toggleSortOrder,
      cleanAnimalFilters,
      setAnimalsFilter,
      $reset,
    };
  },
  {
    persist: true,
  },
);
