import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { defaultContractsFilter } from "@/constants/defaults";
import apiService from "@/core/services/api.service";
import {
  ContractsFilter,
  ContractTable,
  IframeSignaturit,
  OfflineContract,
  OfflineRancher,
  RanchContract,
  Technician,
} from "@/types/types";

import { useAnimalOperatorStore } from "./AnimalOperatorStore";

export const useContractOperatorStore = defineStore(
  "contractOperatorStore",
  () => {
    // State as refs
    const selectedContract = ref<ContractTable>({} as ContractTable);
    const showContractDetails = ref(false);
    const showContractForm = ref(false);
    const toggleLoadingContracts = ref(false);
    const contracts = ref<ContractTable[]>([]);
    const selectedOfflineContract = ref<OfflineContract>({} as OfflineContract);
    const showOfflineContractDetails = ref(false);
    const showOfflineContractForm = ref(false);
    const toggleLoadingOfflineContracts = ref(false);
    const toggleLoadingOfflineRanchers = ref(false);
    const offlineContracts = ref<OfflineContract[]>([]);
    const offlineRanchers = ref<OfflineRancher[]>([]);
    const allRanches = ref<RanchContract[]>([]);
    const technicians = ref<Technician[]>([]);
    const selectedTechnician = ref<Technician>({} as Technician);
    const iframe = ref<IframeSignaturit>({} as IframeSignaturit);
    const offlineIframe = ref<IframeSignaturit>({} as IframeSignaturit);
    const offlineSignRole = ref("");
    const selectedNewOfflineRancher = ref<OfflineRancher>({} as OfflineRancher);
    const offlineContractAnimalList = ref<any[]>([]);
    const showContractsFilter = ref(false);
    const sortColumn = ref("number");
    const sortOrder = ref("desc");
    const sortOrderOptions = ref([
      { label: "Ascendente", value: "asc" },
      { label: "Descendente", value: "desc" },
    ]);
    const sortOptions = ref([
      { label: "Número de Contrato", value: "number" },
      { label: "Nombre", value: "name" },
      { label: "Estado", value: "state.name" },
      { label: "Número de Animales", value: "numberOfAnimals" },
      { label: "Animales Pendientes", value: "pendingAnimals" },
      { label: "Peticiones Pendientes", value: "pendingPetitions" },
      { label: "Explotación", value: "ranch.name" },
    ]);
    const contractsFilter = ref(defaultContractsFilter);

    // Actions as functions
    const fetchAllRanches = async () => {
      const response = await apiService.get("/operador/explotaciones");
      allRanches.value = response.data.content;
    };

    const fetchTechnicians = async () => {
      const response = await apiService.get("/operador/tecnicos");
      technicians.value = response.data.content;
    };

    const fetchContracts = async () => {
      const response = await apiService.get(
        "/operador/contratos/contratos-tabla",
      );
      contracts.value = response.data;
    };

    const fetchAll = async () => {
      await Promise.all([fetchAllRanches(), fetchTechnicians()]);
    };

    const openContractDetails = (contract: ContractTable) => {
      const animalOperatorStore = useAnimalOperatorStore();
      animalOperatorStore.hideAnimalDetails();
      selectedContract.value = contract;
      showContractDetails.value = true;
    };

    const hideContractDetails = () => {
      showContractDetails.value = false;
    };

    const toggleContractDetails = () => {
      const animalOperatorStore = useAnimalOperatorStore();
      animalOperatorStore.hideAnimalDetails();
      showContractDetails.value = !showContractDetails.value;
    };

    const openContractForm = () => {
      showContractForm.value = true;
    };

    const hideContractForm = () => {
      showContractForm.value = false;
    };

    const toggleLoadContracts = () => {
      toggleLoadingContracts.value = !toggleLoadingContracts.value;
    };

    const setContracts = (newContracts: ContractTable[]) => {
      contracts.value = newContracts;
      if (selectedContract.value) {
        const refreshedContract = contracts.value.find(
          (contract) => contract.id === selectedContract.value.id,
        );
        if (refreshedContract) {
          selectedContract.value = refreshedContract;
        }
      }
    };

    // Convert remaining actions...
    const openOfflineContractDetails = (contract: OfflineContract) => {
      selectedOfflineContract.value = contract;
      showOfflineContractDetails.value = true;
    };

    const toggleOfflineContractDetails = () => {
      showOfflineContractDetails.value = !showOfflineContractDetails.value;
    };

    const hideOfflineContractDetails = () => {
      showOfflineContractDetails.value = false;
    };

    const openOfflineContractForm = () => {
      showOfflineContractForm.value = true;
    };

    const hideOfflineContractForm = () => {
      showOfflineContractForm.value = false;
    };

    const toggleLoadOfflineContracts = () => {
      toggleLoadingOfflineContracts.value =
        !toggleLoadingOfflineContracts.value;
    };

    const setOfflineContracts = (newContracts: OfflineContract[]) => {
      offlineContracts.value = newContracts;
      if (selectedOfflineContract.value) {
        const refreshedContract = offlineContracts.value.find(
          (contract) => contract.id === selectedOfflineContract.value.id,
        );
        if (refreshedContract) {
          selectedOfflineContract.value = refreshedContract;
        }
      }
    };

    const toggleLoadOfflineRanchers = () => {
      toggleLoadingOfflineRanchers.value = !toggleLoadingOfflineRanchers.value;
    };

    const addNewOfflineAnimal = (animal: any) => {
      offlineContractAnimalList.value.push({ ...animal });
    };

    const resetNewOfflineContractForm = () => {
      selectedNewOfflineRancher.value = {} as OfflineRancher;
      offlineContractAnimalList.value = [];
    };

    const signOfflineContractRancher = (contractId: number) => {
      offlineSignRole.value = "GANADERO";
      offlineIframe.value.contractId = contractId;
      offlineIframe.value.open = true;
    };

    const signOfflineContractOperator = (contractId: number) => {
      offlineSignRole.value = "OPERADOR";
      offlineIframe.value.contractId = contractId;
      offlineIframe.value.open = true;
    };

    const setTechnicians = (newTechnicians: Technician[]) => {
      technicians.value = newTechnicians;
    };

    const openContractsFilter = () => {
      showContractsFilter.value = true;
    };

    const hideContractsFilter = () => {
      showContractsFilter.value = false;
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
    };

    const cleanContractsFilter = () => {
      contractsFilter.value = defaultContractsFilter;
    };

    const setContractsFilter = (filter: ContractsFilter) => {
      contractsFilter.value = filter;
    };

    // Convert getters to computed properties
    const ranchesOptions = computed(() => {
      if (Array.isArray(allRanches.value) && allRanches.value.length > 0) {
        return allRanches.value.map(({ id, nombre_casa, cea }) => ({
          value: id,
          label: nombre_casa + " - " + cea,
        }));
      }
      return [{ value: 0, label: "No hay resultados" }];
    });

    const techniciansOptions = computed(() => {
      if (Array.isArray(technicians.value) && technicians.value.length > 0) {
        return technicians.value.map((technician: Technician) => ({
          value: technician.id,
          label: technician.nombre,
        }));
      }
      return [{ value: 0, label: "No hay resultados" }];
    });

    // Reset function
    const $reset = () => {
      selectedContract.value = {} as ContractTable;
      showContractDetails.value = false;
      showContractForm.value = false;
      toggleLoadingContracts.value = false;
      contracts.value = [];
      selectedOfflineContract.value = {} as OfflineContract;
      showOfflineContractDetails.value = false;
      showOfflineContractForm.value = false;
      toggleLoadingOfflineContracts.value = false;
      toggleLoadingOfflineRanchers.value = false;
      offlineContracts.value = [];
      offlineRanchers.value = [];
      allRanches.value = [];
      technicians.value = [];
      selectedTechnician.value = {} as Technician;
      iframe.value = {} as IframeSignaturit;
      offlineIframe.value = {} as IframeSignaturit;
      offlineSignRole.value = "";
      selectedNewOfflineRancher.value = {} as OfflineRancher;
      offlineContractAnimalList.value = [];
      showContractsFilter.value = false;
      sortColumn.value = "number";
      sortOrder.value = "desc";
      contractsFilter.value = defaultContractsFilter;
    };

    return {
      // State
      selectedContract,
      showContractDetails,
      showContractForm,
      toggleLoadingContracts,
      contracts,
      selectedOfflineContract,
      showOfflineContractDetails,
      showOfflineContractForm,
      toggleLoadingOfflineContracts,
      toggleLoadingOfflineRanchers,
      offlineContracts,
      offlineRanchers,
      allRanches,
      technicians,
      selectedTechnician,
      iframe,
      offlineIframe,
      offlineSignRole,
      selectedNewOfflineRancher,
      offlineContractAnimalList,
      showContractsFilter,
      sortColumn,
      sortOrder,
      sortOrderOptions,
      sortOptions,
      contractsFilter,
      // Actions
      fetchAllRanches,
      fetchTechnicians,
      fetchContracts,
      fetchAll,
      openContractDetails,
      hideContractDetails,
      toggleContractDetails,
      openContractForm,
      hideContractForm,
      toggleLoadContracts,
      setContracts,
      openOfflineContractDetails,
      toggleOfflineContractDetails,
      hideOfflineContractDetails,
      openOfflineContractForm,
      hideOfflineContractForm,
      toggleLoadOfflineContracts,
      setOfflineContracts,
      toggleLoadOfflineRanchers,
      addNewOfflineAnimal,
      resetNewOfflineContractForm,
      signOfflineContractRancher,
      signOfflineContractOperator,
      setTechnicians,
      openContractsFilter,
      hideContractsFilter,
      toggleSortOrder,
      cleanContractsFilter,
      setContractsFilter,
      // Computed
      ranchesOptions,
      techniciansOptions,
      // Reset
      $reset,
    };
  },
  {
    persist: true,
  },
);
