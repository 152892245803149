import { useIonRouter } from "@ionic/vue";
import { onUnmounted, Ref, watch } from "vue";

// Track active modals
export const activeModals: string[] = [];
export type ModalInfo = {
  isOpen: Ref<boolean>;
  onClose: () => void;
  modalId: string;
};

export const activeModalInfo: ModalInfo[] = [];

export async function clearAllModals(callback?: () => void) {
  // Close all modals in reverse order (last opened first)
  for (let i = activeModalInfo.length - 1; i >= 0; i--) {
    const modalInfo = activeModalInfo[i];
    if (modalInfo.isOpen.value) {
      modalInfo.onClose();
    }
  }

  // Clear the arrays
  activeModals.length = 0;
  activeModalInfo.length = 0;

  if (callback) {
    await callback();
  }
}

export function useModalBackButton(
  isOpen: Ref<boolean>,
  onClose: () => void,
  modalId: string = "modal",
  options?: { skipRouterBack?: boolean },
) {
  const router = useIonRouter();

  const handleBackButton = (event: PopStateEvent) => {
    // Only handle if this is the top-most modal
    if (isOpen.value && activeModals[activeModals.length - 1] === modalId) {
      event.preventDefault();
      onClose();
    }
  };

  watch(
    () => isOpen.value,
    (newIsOpen) => {
      if (newIsOpen) {
        // Add to modal stack
        activeModals.push(modalId);
        activeModalInfo.push({ isOpen, onClose, modalId });
        // Only push state if this is the first modal
        if (activeModals.length === 1) {
          history.pushState({ modalId }, "", window.location.href);
        }
        window.addEventListener("popstate", handleBackButton);
      } else {
        // Remove from modal stack
        const index = activeModals.indexOf(modalId);
        if (index > -1) {
          activeModals.splice(index, 1);
          activeModalInfo.splice(index, 1);
        }
        window.removeEventListener("popstate", handleBackButton);

        // Only go back if this was the last modal in stack
        if (
          activeModals.length === 0 &&
          history.state?.modalId === modalId &&
          !options?.skipRouterBack
        ) {
          router.back();
        }
      }
    },
  );

  onUnmounted(() => {
    const index = activeModals.indexOf(modalId);
    if (index > -1) {
      activeModals.splice(index, 1);
      activeModalInfo.splice(index, 1);
    }
    window.removeEventListener("popstate", handleBackButton);
  });

  return {
    activeModals,
    clearAllModals,
  };
}
