import { defineStore } from "pinia";
import { computed, ref } from "vue";

import apiService from "@/core/services/api.service";
import { Rate } from "@/types/types";

export const useRateStore = defineStore("rateStore", () => {
  const allRates = ref<Rate[]>([]);
  const ratesPageContent = ref<Rate[]>([]);
  const toggleLoadingRates = ref(false);

  const fetchAllRates = async () => {
    try {
      const response = await apiService.get("/rates/all");
      allRates.value = response.data;
    } catch (error) {
      console.error("Error fetching rates:", error);
    }
  };

  const checkAndFetchRates = async () => {
    if (allRates.value.length === 0) {
      await fetchAllRates();
    }
  };

  const setRates = (newRates: Rate[]) => {
    allRates.value = newRates;
  };

  const setRatesPageContent = (newRates: Rate[]) => {
    ratesPageContent.value = newRates;
  };

  const toggleLoadRates = () => {
    toggleLoadingRates.value = !toggleLoadingRates.value;
  };

  const ratesOptions = computed(() => {
    if (Array.isArray(allRates.value) && allRates.value.length > 0) {
      return allRates.value.map((rate: Rate) => ({
        value: rate.id,
        label: rate.name,
      }));
    } else {
      return [{ value: 0, label: "No hay resultados" }];
    }
  });

  const $reset = () => {
    allRates.value = [];
    ratesPageContent.value = [];
    toggleLoadingRates.value = false;
  };

  return {
    allRates,
    ratesPageContent,
    toggleLoadingRates,
    fetchAllRates,
    checkAndFetchRates,
    setRates,
    setRatesPageContent,
    toggleLoadRates,
    ratesOptions,
    $reset,
  };
});
