import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { defaultAnimalsFilter } from "@/constants/defaults";
import rancherServices from "@/core/services/rancherServices";
import { AnimalsFilter, AnimalTable, Ranch } from "@/types/types";

import { useContractRancherStore } from "./ContractRancherStore";

export const useAnimalRancherStore = defineStore(
  "animalRancherStore",
  () => {
    // State as refs
    const animals = ref<AnimalTable[]>([]);
    const selectedAnimal = ref<AnimalTable>({} as AnimalTable);
    const showNewAnimalForm = ref(false);
    const showAnimalDetails = ref(false);
    const showAnimalDetailsSell = ref(false);
    const showAnimalDetailsBuy = ref(false);
    const showSlaughterForm = ref(false);
    const showDeliveryForm = ref(false);
    const showUploadAnimalsExcel = ref(false);
    const showAnimalImageUpload = ref(false);
    const showAnimalEditForm = ref(false);
    const showAnimalDismissForm = ref(false);
    const showSellAnimalForm = ref(false);
    const toggleLoadingAnimals = ref(false);
    const toggleLoadingAddAnimalsToContract = ref(false);
    const scannedAnimal = ref({});
    const myRanches = ref<Ranch[]>([]);
    const showAnimalsFilter = ref(false);
    const sortColumn = ref("id");
    const sortOrder = ref("desc");
    const sortOrderOptions = ref([
      { label: "Ascendente", value: "asc" },
      { label: "Descendente", value: "desc" },
    ]);
    const sortOptions = ref([
      { label: "Recientes", value: "id" },
      { label: "Crotal", value: "crotal" },
      { label: "Estado", value: "state.name" },
      { label: "Raza", value: "breed.name" },
      { label: "Sexo", value: "sex.name" },
      { label: "Fecha de Nacimiento", value: "birthDate" },
      { label: "Explotación", value: "ranchId.cea" },
    ]);
    const animalsFilter = ref({ ...defaultAnimalsFilter });
    const selectedAnimalsToSell = ref<AnimalTable[]>([]);
    const selectedAnimalsAddToContract = ref<AnimalTable[]>([]);

    // Computed properties (converted from getters)
    const myRanchesOptions = computed(() => {
      return myRanches.value.map((ranch) => ({
        label: ranch.nombre_casa + " - " + ranch.cea,
        value: ranch,
      }));
    });

    const myRanchesOptionsWithAll = computed(() => {
      const defaultOption = {
        label: "Todas las explotaciones",
        value: "all",
      };
      return myRanchesOptions.value.length > 0
        ? [defaultOption, ...myRanchesOptions.value]
        : [defaultOption];
    });

    const myRanchesOptionsId = computed(() => {
      return myRanches.value.map((ranch) => ({
        label: ranch.nombre_casa + " - " + ranch.cea,
        value: ranch.id,
      }));
    });

    const myRanchesOptionsWithAllId = computed(() => {
      const defaultOption = {
        label: "Todas las explotaciones",
        value: null,
      };
      return myRanchesOptionsId.value.length > 0
        ? [defaultOption, ...myRanchesOptionsId.value]
        : [defaultOption];
    });

    // Actions as functions
    const fetchMyRanches = async () => {
      try {
        const response = await rancherServices.getMyRanches();
        myRanches.value = response.data.content;
      } catch (error) {
        console.error("Error fetching my ranches:", error);
      }
    };

    const checkAndFetchMyRanches = async () => {
      if (myRanches.value.length === 0) {
        await fetchMyRanches();
      }
    };

    // ... convert remaining actions to functions
    const toggleLoadAnimals = () => {
      toggleLoadingAnimals.value = !toggleLoadingAnimals.value;
    };

    const toggleLoadAddAnimalsToContract = () => {
      toggleLoadingAddAnimalsToContract.value =
        !toggleLoadingAddAnimalsToContract.value;
    };

    const setAnimals = (newAnimals: AnimalTable[]) => {
      animals.value = newAnimals;
      if (selectedAnimal.value) {
        const refreshedAnimal = animals.value.find(
          (animal) => animal.id === selectedAnimal.value.id,
        );
        if (refreshedAnimal) {
          selectedAnimal.value = refreshedAnimal;
        }
      }
    };

    // ... convert visibility toggle functions
    const openAnimalDetails = (animal: AnimalTable) => {
      const contractRancherStore = useContractRancherStore();
      contractRancherStore.hideContractDetails();
      selectedAnimal.value = animal;
      showAnimalDetailsBuy.value = false;
      showAnimalDetailsSell.value = false;
      showAnimalDetails.value = true;
    };

    const hideAnimalDetails = () => {
      showAnimalDetails.value = false;
    };

    // ... convert remaining visibility functions similarly
    const toggleAnimalDetails = () => {
      const contractRancherStore = useContractRancherStore();
      contractRancherStore.hideContractDetails();
      showAnimalDetailsBuy.value = false;
      showAnimalDetailsSell.value = false;
      showAnimalDetails.value = !showAnimalDetails.value;
    };

    const openAnimalDetailsBuy = (animal: AnimalTable) => {
      selectedAnimal.value = animal;
      showAnimalDetails.value = false;
      showAnimalDetailsSell.value = false;
      showAnimalDetailsBuy.value = true;
    };

    const hideAnimalDetailsBuy = () => {
      showAnimalDetailsBuy.value = false;
    };

    const toggleAnimalDetailsBuy = () => {
      showAnimalDetailsSell.value = false;
      showAnimalDetails.value = false;
      showAnimalDetailsBuy.value = !showAnimalDetailsBuy.value;
    };

    const openAnimalDetailsSell = (animal: AnimalTable) => {
      selectedAnimal.value = animal;
      showAnimalDetailsBuy.value = false;
      showAnimalDetails.value = false;
      showAnimalDetailsSell.value = true;
    };

    const hideAnimalDetailsSell = () => {
      showAnimalDetailsSell.value = false;
    };

    const toggleAnimalDetailsSell = () => {
      showAnimalDetailsBuy.value = false;
      showAnimalDetails.value = false;
      showAnimalDetailsSell.value = !showAnimalDetailsSell.value;
    };

    const openNewAnimalForm = () => {
      showNewAnimalForm.value = true;
    };

    const hideNewAnimalForm = () => {
      showNewAnimalForm.value = false;
    };

    const openAnimalImageUpload = () => {
      showAnimalImageUpload.value = true;
    };

    const hideAnimalImageUpload = () => {
      showAnimalImageUpload.value = false;
    };

    const openAnimalEditForm = () => {
      showAnimalEditForm.value = true;
    };

    const hideAnimalEditForm = () => {
      showAnimalEditForm.value = false;
    };

    const openAnimalDismissForm = () => {
      showAnimalDismissForm.value = true;
    };

    const hideAnimalDismissForm = () => {
      showAnimalDismissForm.value = false;
    };

    const openSellAnimalForm = () => {
      showSellAnimalForm.value = true;
    };

    const hideSellAnimalForm = () => {
      showSellAnimalForm.value = false;
    };

    const openUploadAnimalsExcel = () => {
      showUploadAnimalsExcel.value = true;
    };

    const hideUploadAnimalsExcel = () => {
      showUploadAnimalsExcel.value = false;
    };

    const openSlaughterForm = (animal: AnimalTable) => {
      selectedAnimal.value = animal;
      showSlaughterForm.value = true;
    };

    const hideSlaughterForm = () => {
      showSlaughterForm.value = false;
    };

    const openDeliveryForm = (animal: AnimalTable) => {
      selectedAnimal.value = animal;
      showDeliveryForm.value = true;
    };

    const hideDeliveryForm = () => {
      showDeliveryForm.value = false;
    };

    const openAnimalsFilter = () => {
      showAnimalsFilter.value = true;
    };

    const hideAnimalsFilter = () => {
      showAnimalsFilter.value = false;
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
    };

    const cleanAnimalFilters = () => {
      animalsFilter.value = defaultAnimalsFilter;
    };

    const setAnimalsFilter = (filters: AnimalsFilter) => {
      animalsFilter.value = filters;
    };

    // ... convert animal management functions
    const addAnimalToSell = (animal: AnimalTable) => {
      selectedAnimalsToSell.value.push(animal);
    };

    const removeAnimalToSell = (animal: AnimalTable) => {
      selectedAnimalsToSell.value = selectedAnimalsToSell.value.filter(
        (selectedAnimal) => selectedAnimal.id !== animal.id,
      );
    };

    const clearSelectedAnimalsToSell = () => {
      selectedAnimalsToSell.value = [];
    };

    const isAnimalSelectedToSell = (animal: AnimalTable) => {
      return selectedAnimalsToSell.value.some(
        (selectedAnimal) => selectedAnimal.id === animal.id,
      );
    };

    // ... convert contract-related functions
    const addAnimalToContract = (animal: AnimalTable) => {
      selectedAnimalsAddToContract.value.push(animal);
    };

    const removeAnimalFromContract = (animal: AnimalTable) => {
      selectedAnimalsAddToContract.value =
        selectedAnimalsAddToContract.value.filter(
          (selectedAnimal) => selectedAnimal.id !== animal.id,
        );
    };

    const clearSelectedAnimalsAddToContract = () => {
      selectedAnimalsAddToContract.value = [];
    };

    const isAnimalSelectedAddToContract = (animal: AnimalTable) => {
      return selectedAnimalsAddToContract.value.some(
        (selectedAnimal) => selectedAnimal.id === animal.id,
      );
    };

    // Reset function
    const $reset = () => {
      animals.value = [];
      selectedAnimal.value = {} as AnimalTable;
      showNewAnimalForm.value = false;
      showAnimalDetails.value = false;
      showAnimalDetailsSell.value = false;
      showAnimalDetailsBuy.value = false;
      showSlaughterForm.value = false;
      showDeliveryForm.value = false;
      showUploadAnimalsExcel.value = false;
      showAnimalImageUpload.value = false;
      showAnimalEditForm.value = false;
      showAnimalDismissForm.value = false;
      showSellAnimalForm.value = false;
      toggleLoadingAnimals.value = false;
      toggleLoadingAddAnimalsToContract.value = false;
      scannedAnimal.value = {};
      myRanches.value = [];
      showAnimalsFilter.value = false;
      sortColumn.value = "id";
      sortOrder.value = "desc";
      animalsFilter.value = { ...defaultAnimalsFilter };
      selectedAnimalsToSell.value = [];
      selectedAnimalsAddToContract.value = [];
    };

    return {
      // State
      animals,
      selectedAnimal,
      showNewAnimalForm,
      showAnimalDetails,
      showAnimalDetailsSell,
      showAnimalDetailsBuy,
      showSlaughterForm,
      showDeliveryForm,
      showUploadAnimalsExcel,
      showAnimalImageUpload,
      showAnimalEditForm,
      showAnimalDismissForm,
      showSellAnimalForm,
      toggleLoadingAnimals,
      toggleLoadingAddAnimalsToContract,
      scannedAnimal,
      myRanches,
      showAnimalsFilter,
      sortColumn,
      sortOrder,
      sortOrderOptions,
      sortOptions,
      animalsFilter,
      selectedAnimalsToSell,
      selectedAnimalsAddToContract,
      // Computed
      myRanchesOptions,
      myRanchesOptionsWithAll,
      myRanchesOptionsId,
      myRanchesOptionsWithAllId,
      // Actions
      fetchMyRanches,
      checkAndFetchMyRanches,
      toggleLoadAnimals,
      toggleLoadAddAnimalsToContract,
      setAnimals,
      openAnimalDetails,
      hideAnimalDetails,
      toggleAnimalDetails,
      openAnimalDetailsBuy,
      hideAnimalDetailsBuy,
      toggleAnimalDetailsBuy,
      openAnimalDetailsSell,
      hideAnimalDetailsSell,
      toggleAnimalDetailsSell,
      openNewAnimalForm,
      hideNewAnimalForm,
      openAnimalImageUpload,
      hideAnimalImageUpload,
      openAnimalEditForm,
      hideAnimalEditForm,
      openAnimalDismissForm,
      hideAnimalDismissForm,
      openSellAnimalForm,
      hideSellAnimalForm,
      openUploadAnimalsExcel,
      hideUploadAnimalsExcel,
      openSlaughterForm,
      hideSlaughterForm,
      openDeliveryForm,
      hideDeliveryForm,
      openAnimalsFilter,
      hideAnimalsFilter,
      toggleSortOrder,
      cleanAnimalFilters,
      setAnimalsFilter,
      addAnimalToSell,
      removeAnimalToSell,
      clearSelectedAnimalsToSell,
      isAnimalSelectedToSell,
      addAnimalToContract,
      removeAnimalFromContract,
      clearSelectedAnimalsAddToContract,
      isAnimalSelectedAddToContract,
      $reset,
    };
  },
  {
    persist: true,
  },
);
