import { defineStore } from "pinia";
import { ref } from "vue";

import { AdvertStates, PriceTypes } from "@/constants/constants";
import {
  defaultAdvertFilters,
  defaultAdvertProposalFilters,
} from "@/constants/defaults";
import type {
  Advert,
  AdvertProposal,
  AdvertProposalResponse,
  AdvertProposalsFilter,
  AdvertResponseFeria,
  MyAdvertResponse,
} from "@/types/types";

export const useFeriaStore = defineStore(
  "feriaStore",
  () => {
    // State as refs
    const adverts = ref<Advert[]>([]);
    const selectedAdvert = ref<Advert>({} as Advert);
    const AdvertResponses = ref<AdvertResponseFeria[]>([]);
    const selectecAdvertResponse = ref<AdvertResponseFeria>(
      {} as AdvertResponseFeria,
    );
    const MyAdvertResponses = ref<MyAdvertResponse[]>([]);
    const selectedMyAdvertResponse = ref<MyAdvertResponse>(
      {} as MyAdvertResponse,
    );
    const toggleLoadingAdverts = ref(false);
    const showFeriaFilters = ref(false);
    const advertFilters = ref({ ...defaultAdvertFilters });
    const advertSortColumn = ref("id");
    const advertSortOrder = ref("desc");
    const advertSortOrderOptions = ref([
      { label: "Ascendente", value: "asc" },
      { label: "Descendente", value: "desc" },
    ]);
    const advertSortOptions = ref([
      { label: "Recientes", value: "id" },
      { label: "Precio", value: "price" },
      { label: "Número de animales", value: "numberOfAnimals" },
    ]);
    const advertStatesOptions = ref([
      { label: "Todos", value: AdvertStates.ALL },
      { label: "En venta", value: AdvertStates.ACTIVE },
      { label: "Vendidos", value: AdvertStates.SOLD },
    ]);
    const advertProposalsOptions = ref([
      { label: "Todos", value: "all" },
      { label: "Con propuestas", value: "withProposals" },
      { label: "Con propuestas abiertas", value: "withOpenProposals" },
    ]);
    const priceTypesOptions = ref([
      { label: "Todos", value: PriceTypes.ALL },
      { label: "Tarifa", value: PriceTypes.RATE },
      { label: "Precio fijo", value: PriceTypes.FIXED_PRICE },
    ]);
    const advertProposals = ref<AdvertProposal[]>([]);
    const advertProposalResponses = ref<AdvertProposalResponse[]>([]);
    const selectedAdvertProposal = ref<AdvertProposal>({} as AdvertProposal);
    const selectedAdvertProposalResponse = ref<AdvertProposalResponse>(
      {} as AdvertProposalResponse,
    );
    const toggleLoadingAdvertProposals = ref(false);
    const showAdvertProposalFilters = ref(false);
    const advertProposalFilters = ref({ ...defaultAdvertProposalFilters });
    const advertProposalSortColumn = ref("id");
    const advertProposalSortOrder = ref("desc");
    const advertProposalSortOrderOptions = ref([
      { label: "Ascendente", value: "asc" },
      { label: "Descendente", value: "desc" },
    ]);
    const advertProposalSortOptions = ref([
      { label: "Recientes", value: "id" },
      { label: "Precio", value: "price" },
      { label: "Cantidad de animales", value: "quantity" },
    ]);

    // Actions as functions
    const toggleLoadAdverts = () => {
      toggleLoadingAdverts.value = !toggleLoadingAdverts.value;
    };

    const hideFeriaFilters = () => {
      showFeriaFilters.value = false;
    };

    const openFeriaFilters = () => {
      showFeriaFilters.value = true;
    };

    const setAdvertFilters = (filters: any) => {
      advertFilters.value = filters;
    };

    const cleanAdvertFilters = () => {
      advertFilters.value = { ...defaultAdvertFilters };
    };

    const toggleAdvertSortOrder = () => {
      advertSortOrder.value = advertSortOrder.value === "asc" ? "desc" : "asc";
    };

    const toggleLoadAdvertProposals = () => {
      toggleLoadingAdvertProposals.value = !toggleLoadingAdvertProposals.value;
    };

    const hideAdvertProposalFilters = () => {
      showAdvertProposalFilters.value = false;
    };

    const openAdvertProposalFilters = () => {
      showAdvertProposalFilters.value = true;
    };

    const setAdvertProposalFilters = (filters: AdvertProposalsFilter) => {
      advertProposalFilters.value = filters;
    };

    const cleanAdvertProposalFilters = () => {
      advertProposalFilters.value = defaultAdvertProposalFilters;
    };

    const toggleAdvertProposalSortOrder = () => {
      advertProposalSortOrder.value =
        advertProposalSortOrder.value === "asc" ? "desc" : "asc";
    };

    // Reset function
    const $reset = () => {
      adverts.value = [];
      selectedAdvert.value = {} as Advert;
      AdvertResponses.value = [];
      selectecAdvertResponse.value = {} as AdvertResponseFeria;
      MyAdvertResponses.value = [];
      selectedMyAdvertResponse.value = {} as MyAdvertResponse;
      toggleLoadingAdverts.value = false;
      showFeriaFilters.value = false;
      advertFilters.value = { ...defaultAdvertFilters };
      advertSortColumn.value = "id";
      advertSortOrder.value = "desc";
      advertProposals.value = [];
      advertProposalResponses.value = [];
      selectedAdvertProposal.value = {} as AdvertProposal;
      selectedAdvertProposalResponse.value = {} as AdvertProposalResponse;
      toggleLoadingAdvertProposals.value = false;
      showAdvertProposalFilters.value = false;
      advertProposalFilters.value = { ...defaultAdvertProposalFilters };
      advertProposalSortColumn.value = "id";
      advertProposalSortOrder.value = "desc";
    };

    return {
      // State
      adverts,
      selectedAdvert,
      AdvertResponses,
      selectecAdvertResponse,
      MyAdvertResponses,
      selectedMyAdvertResponse,
      toggleLoadingAdverts,
      showFeriaFilters,
      advertFilters,
      advertSortColumn,
      advertSortOrder,
      advertSortOrderOptions,
      advertSortOptions,
      advertStatesOptions,
      advertProposalsOptions,
      priceTypesOptions,
      advertProposals,
      advertProposalResponses,
      selectedAdvertProposal,
      selectedAdvertProposalResponse,
      toggleLoadingAdvertProposals,
      showAdvertProposalFilters,
      advertProposalFilters,
      advertProposalSortColumn,
      advertProposalSortOrder,
      advertProposalSortOrderOptions,
      advertProposalSortOptions,
      // Actions
      toggleLoadAdverts,
      hideFeriaFilters,
      openFeriaFilters,
      setAdvertFilters,
      cleanAdvertFilters,
      toggleAdvertSortOrder,
      toggleLoadAdvertProposals,
      hideAdvertProposalFilters,
      openAdvertProposalFilters,
      setAdvertProposalFilters,
      cleanAdvertProposalFilters,
      toggleAdvertProposalSortOrder,
      $reset,
    };
  },
  {
    persist: true,
  },
);
